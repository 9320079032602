import React,{ Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import './breakCrumbs.css';
import breadcrumbNameMap from '../../configs/MenuConfig';
import PropTypes from "prop-types";


const breadMap={ };
breadcrumbNameMap.map((item)=>{
    breadMap[item.key]=item.title;
    if(item.children){
        item.children.map((item1)=>{
            breadMap[item1.key]=item1.title;
        })
    }
})
class BreadcrumbCustom extends Component {
    //利用PropTypes记住所跳转每个页面的位置 
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props, context) {
        super(props, context);
        this.state = {
            pathSnippets: null,
            extraBreadcrumbItems: null
        }
    }
    getPath() {
	//对路径进行切分，存放到this.state.pathSnippets中
        this.state.pathSnippets = 
        this.context.router.history.location.pathname.split('/').filter(i => i);
        // console.log(this.context.router.history.location.pathname)
        // console.log(this.state.pathSnippets)
	//将切分的路径读出来，形成面包屑，存放到this.state.extraBreadcrumbItems
        this.state.extraBreadcrumbItems = this.state.pathSnippets.map((_, index) => {
            const url = `/${this.state.pathSnippets.slice(0, index + 1).join('/')}`;
            // console.log(`url${index}:${url}`)
            return (
                <Breadcrumb.Item key={url}>
                    <Link to={url}>
                        {breadMap[url]}
                    </Link>
                </Breadcrumb.Item>
            );
        });
    }
 
    componentWillMount() {
        this.getPath();
    }
    componentWillReceiveProps(){
	//任何子页面发生改变，均可调用，完成路径切分以及形成面包屑
        this.getPath();
    }
    render() {
        return (
            <span>
                <Breadcrumb style={{ margin: '12px 0' }}>
                    {this.state.extraBreadcrumbItems}
                </Breadcrumb> 
            </span>
        )
    }
}

export default BreadcrumbCustom;