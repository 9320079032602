import React,{ Component } from 'react';
import { Icon , Popconfirm } from 'antd';
import PropTypes from 'prop-types'; // context
import "./nav.css";
import logo from '../../styles/images/logo.png';
import userImg from '../../styles/images/u48.jpg';

class Nav extends Component{  
    constructor(props){
        super(props)
        this.state={
            userName:localStorage.userName
        }
        this.confirm=this.confirm.bind(this)
    }
    
    static contextTypes = {
        router: PropTypes.object.isRequired
    }
    confirm() {
        this.context.router.history.push('/login');
    }
    render(){
        const text = '退出登录？';
        return(
            <div className='headerLayout'>
                <div className='headerLogo'>
                    <img src={logo} alt=''/>
                </div>
                <Popconfirm 
                        placement="bottomRight" 
                        title={text} 
                        icon={<Icon type="logout" style={{ color: '#40a9ff' }}/>}
                        onConfirm={this.confirm} okText="Yes" cancelText="No">                    
                    <div className='userInfo'>
                        {/* <Icon className='iconStyle' type="bell" /> */}
                        <img src={userImg} alt=''/>
                        <span>{this.state.userName}</span>
                    </div>
                </Popconfirm>
            </div>
        )
    }
}

export default Nav;