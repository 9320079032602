import React,{ Component } from 'react';
import { Route, Switch,Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import './homePage.css';
import SideBar from '../../components/sideBar/sideBar';
import Nav from '../../components/nav/nav';
import Bread from '../../components/breakCrumbs/breakCrumbs';
import roomManage from '../../components/roomManage/roomManage';
import feedBack from '../../components/feedBack/feedBack';
import superviceClass from '../../components/superviceClass/superviceClass';
// import video from '../../components/rtmpVideo/rtmpVideo'

const { Header, Content, Sider } = Layout;

class homePage extends Component{
    state = {
        title: ''
    };
    _setTitle = ({ title }) => {
        if (this.state.title === title) return;
        this.setState({ title });
    }

    render(){
        return(
            <Layout style={{height:'100%'}}>
                <Header style={{ background: '#fff'}}>
                    <Nav></Nav>
                </Header>
                <Layout>
                    <Sider width={240} style={{ background: '#fff' }}>
                        <SideBar/>
                    </Sider>
                    {/* <TestSide></TestSide> */}
                    <Layout style={{ padding: '0 24px 24px' }}>
                        <Bread/>
                        <Content style={{
                            background: '#fff', padding: 24, margin: 0, minHeight: 280,
                        }}
                        >
                        {/* <Routes /> */}
                        <Switch>
                            <Route path='/home' exact render={()=>(
                                //exact是用于精准匹配，这里是定义了路由重定向，意思是当我们进入"/"时会重定向到“/index”
                                <Redirect to='/home/liveRoom/roomManage'/>
                            )}/>
                            <Route path='/home/liveRoom/roomManage' component={roomManage}></Route>
                            <Route path='/home/liveRoom/feedBack' component={feedBack}></Route>
                            <Route path='/home/center/superviceClass' component={superviceClass}></Route>
                            {/* <Route path='/home/center/video' component={video}></Route> */}
                        </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}

export default homePage;