import React,{ Component } from 'react';
import { Button ,Modal, Divider,message } from 'antd';
import InlineForm from './queryInputs/queryInputs';
import Table from '../commonTable/commonTable';
import ModalForm from './fromModal/fromModal';
import baseUrl from './../../configs/postUrl';
import timeFormat from '../../configs/timetool';
import dataConfig from '../../configs/fetchConfig';
import moment from 'moment';

message.config({
  top: 300,
  duration: 2,
  maxCount: 3,
});

class roomManage extends Component{
  constructor(props){
    super(props)
    this.state={
      token:localStorage.userToken,
      roomLists:[],//房间列表
      page:'',    //表格页码配置
      loading:true,
      visible: false,
      modalTitle:'',
      roomDetail:{},//房间详情信息
      //查询房间列表参数
      userId:'',
      id:'',
      teacherNameKeyword:'',
      pageSize:6,
      p:1,
      // 创建房间数据
      skype:'',
      roomName:'',
      startTime:'',
      endTime:'',
      teacherName:'',
      teacherCountry:'',
      teacherEmail:'',
    }
    this.getRoomList=this.getRoomList.bind(this)
    this.getData=this.getData.bind(this)
    this.getQueryData=this.getQueryData.bind(this)
    this.dealWithColum=this.dealWithColum.bind(this)
  }

  componentWillMount(){
    this.getRoomList()
  }
  
  //打开弹框
  showModal = (parm,id) => {
    const title= parm?'新建房间':'修改房间'
    this.setState({
      visible: true,
      modalTitle:title
    });  
    if(!parm){
      this.getRoomDetail(id)
    }
  } 

  // 获取房间信息
  getRoomDetail(id){
    let formData = new FormData();
    formData.append('token', this.state.token)
    formData.append('id',id)

    fetch(baseUrl+'/v1/recruit-live/room-detail',dataConfig(formData))
    
    .then(res => res.json())  ///解析json数据
    .then(data => {
      if(data.code===200){
        this.setState({
          roomDetail:data.data.recruitRoom
        },()=>{this.setFormValue()})
      }else if(data.code===-1){
        message.info(data.msg)
      } 
    })
    .catch(e => console.log('错误:', e))   ///请求出错
  }

  // 房间数据显示
  setFormValue(){
    let demo=this.refs.getFormVlaue;
    const starttime=timeFormat(this.state.roomDetail.startTime)
    const endtime=timeFormat(this.state.roomDetail.endTime)
    // const dateFormat='YYYY-MM-DD HH:mm:ss';
    demo.setFieldsValue({roomName:this.state.roomDetail.roomName})
    demo.setFieldsValue({skype:this.state.roomDetail.skype})
    demo.setFieldsValue({'range-time-picker':[moment(starttime), moment(endtime)]})
    demo.setFieldsValue({teacherName:this.state.roomDetail.teacherName})
    demo.setFieldsValue({teacherCountry:this.state.roomDetail.teacherCountry})
    demo.setFieldsValue({teacherEmail:this.state.roomDetail.teacherEmail})
  }

  //获取弹框内表单数据
  handleOk = () => {
    let demo=this.refs.getFormVlaue;
    demo.validateFields((err, fieldsValue) => {
      if(!err){
        //这里可以拿到数据
        const rangeTimeValue = fieldsValue['range-time-picker'];
        console.log(rangeTimeValue)
        const values = {
          ...fieldsValue,
          'range-time-picker': [
            rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'),
            rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss'),
          ],
        };

        // 弹框操作
        this.setState({
          skype:values.skype?values.skype:'',
          roomName:values.roomName,
          startTime:values['range-time-picker'][0],
          endTime:values['range-time-picker'][1],
          teacherName:values.teacherName?values.teacherName:'',
          teacherCountry:values.teacherCountry?values.teacherCountry:0,
          teacherEmail:values.teacherEmail?values.teacherEmail:'',
          confirmLoading: true,
        },()=>{
          this.creatRoom()
        });
      }
    });
  }

  // 弹框点击取消
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }

  // 创建直播房间
  creatRoom(){

    let formData = new FormData();
    formData.append('token', this.state.token)
    formData.append('skype', this.state.skype)
    formData.append('roomName', this.state.roomName)
    formData.append('startTime', this.state.startTime)
    formData.append('endTime', this.state.endTime)
    formData.append('teacherName', this.state.teacherName)
    formData.append('teacherCountry', this.state.teacherCountry)
    formData.append('teacherEmail', this.state.teacherEmail)
  
    fetch(baseUrl+'/v1/recruit-live/create-room',dataConfig(formData))
    
    .then(res => res.json())  ///解析json数据
    .then(data => {
      // 弹框消失
      if(data.code===200){
        message.info('操作成功');
        setTimeout(() => {
          this.setState({
            visible: false,
            confirmLoading: false,
          });
          this.getRoomList()
        }, 1000);
      }else{
        message.info(data.msg);
      }
    })
    .catch(e => console.log('错误:', e))   ///请求出错
  }
  //获取创建人、房间号、外教
  getQueryData(values){
    this.setState({
      userId:values.username,
      teacherNameKeyword:values.teacher,
      id:values.roomnum,
      p:1
    },()=>{this.getRoomList()})
  }
  // 获取房间列表
  getRoomList(){
    this.setState({loading:true})
    let formData = new FormData();
    formData.append('token', this.state.token)
    formData.append('userId', this.state.userId==undefined?'':this.state.userId)
    formData.append('id', this.state.id==undefined?'':this.state.id)
    formData.append('teacherNameKeyword', this.state.teacherNameKeyword==undefined?'':this.state.teacherNameKeyword)
    formData.append('pageSize', this.state.pageSize)
    formData.append('p', this.state.p)

    fetch(baseUrl+'/v1/recruit-live/room-list',dataConfig(formData))
    
    .then(res => res.json())  ///解析json数据
    .then(data => {
        if(data.code===200){
          this.setState({
            roomLists:data.data.recruitRooms,
            loading:false,
            page:data.data.pages,
          });
          if(JSON.stringify(data.data.recruitRooms) === '[]'){
            message.info('无数据');
          }else{

            // 有值时才能处理表格数据
            const roomList= this.dealWithColum(data.data.recruitRooms)
            this.setState({
              roomLists:roomList,
            });
          }
        }else if(data.code===-1){
          message.info(data.msg)
        }else if(data.code===-2||data.code===-3){
          message.warning(data.msg)
          this.props.history.push('/login')
        }
    })
    .catch(e => console.log('错误:', e))   ///请求出错
  }
  
  // 处理表格数据
  dealWithColum(roomList){
    roomList.map((item,key)=>{
      if(item.author){
        roomList[key].authorName=item.author.realName
      }
      if(item.country){
        roomList[key].country=item.country.country
      }
      roomList[key].startTime=timeFormat(item.startTime)
      roomList[key].endTime=timeFormat(item.endTime)
      roomList[key].createdTime=timeFormat(item.createdTime)
      // if(JSON.stringify(item.invitationCodes)){
      //   roomList[key].teacherCode=`${item.id}/${item.invitationCodes[0].invitationCode}`
      //   roomList[key].studentCode=`${item.id}/${item.invitationCodes[1].invitationCode}`       
      // }
      if(item.customRoomInfo){
        roomList[key].teacherCode=item.customRoomInfo.teacherSite
        roomList[key].studentCode=item.customRoomInfo.studentSite       
      }
    })
    return roomList;
  }

  //表格分页
  getData(page){
    this.setState({
      p:page
    },()=>{
      this.getRoomList()
    })
  }

  render(){
    const columns = [{
      key:'RoomCode',
      title: '房间号',
      dataIndex: 'roomCode',
    },{
      key:'CreatedTime',
      title: '创建时间',
      dataIndex: 'createdTime',
    }, {
      key:'AuthorName',
      title: '创建人',
      dataIndex: 'authorName',
    }, {
      key:'RoomName',
      title: '房间名称',
      dataIndex: 'roomName',
    },{
      key:'StartTime',
      title: '开始时间',
      dataIndex: 'startTime',
    }, {
      key:'EndTime',
      title: '结束时间',
      dataIndex: 'endTime',
    },
    // {
    //   key:'TeacherCode',
    //   title: '教师邀请码',
    //   align:'center',
    //   dataIndex: 'teacherCode',
    //   render: (text, record) =>(
    //     <span>
    //       https://livestream.ehailuo.com/#/home/<br/>{text}
    //     </span>
    //   )
    // },
    // {
    //   key:'StudentCode',
    //   align:'center',
    //   title: '学生邀请码',
    //   dataIndex: 'studentCode',
    //   render: (text, record) =>(
    //     <span>
    //       https://livestream.ehailuo.com/#/home/<br/>{text}
    //     </span>
    //   ) 
    // },
    {
      key:'TeacherCode',
      title: '教师邀请码',
      dataIndex: 'teacherCode',
    },{
      key:'StudentCode',
      title: '学生邀请码',
      dataIndex: 'studentCode',
    },
    {
      key:'TeacherName',
      title: '外教名称',
      dataIndex: 'teacherName',
    },{
      key:'Country',
      title: '外教国家',
      dataIndex: 'country',
    },{
      key:'TeacherEmail',
      title: '外教邮箱',
      dataIndex: 'teacherEmail',
    },{
      key:'Skype',
      title: 'TA skype',
      dataIndex: 'skype',
    },{
      key:'Caozuo',
      title: '操作', 
      dataIndex: 'id',
      width:120,
      render: (text, record) => (
          <div>
            <span 
              style={{color: '#1890ff',cursor:'pointer'}}
              onClick={this.showModal.bind(this,false,text)}>修改</span>
            {/* <Divider type="vertical" />
            <a 
              href={`https://livestream.ehailuo.com/#/feedback?userid=${localStorage.sendId}&realname=${localStorage.realName}&roomCode=${record.roomCode}`} target="_blank">
            反馈</a> */}
          </div>
      ),
    }];
    return (
      <div>
        {/* 创建房间弹窗 */}
        <Modal
          title={this.state.modalTitle}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskClosable={false}
          width={'50%'}
          okText={'确定'}
          cancelText={'取消'}
          destroyOnClose={true}
        > 
          <ModalForm  ref="getFormVlaue"></ModalForm>
        </Modal>

        <Button 
          onClick={this.showModal.bind(this,true)}
          style={{marginBottom:'10px'}} type="primary">新建房间</Button>
        
        {/* 查询项 */}
        <InlineForm 
          queryData={this.getQueryData}></InlineForm>

        {/* 表格 */}
        <Table 
          getData={this.getData}
          loading={this.state.loading}
          pages={this.state.page}
          data={this.state.roomLists} 
          columns={columns}></Table>
      </div>
    );
  }
}

export default roomManage;
