const MenuConfig = [
    {
        title:'直播教室',
        key:'/home/liveRoom',
        url:'/home/liveRoom',
        icon:'home',
        children: [
            {
                title: '直播房间管理',
                key: '/home/liveRoom/roomManage',
                url:'/home/liveRoom/roomManage'
            },
            {
                title: '直播意见反馈',
                key: '/home/liveRoom/feedBack',
                url: '/home/liveRoom/feedBack'
            }
        ]              
    },
    {
        title:'TA center',
        key:'/home/center',
        url:'/home/center',
        icon:'home',
        children: [
            {
                title: 'TA 监课管理',
                key: '/home/center/superviceClass',
                url:'/home/center/superviceClass'
            }
        ]              
    }
]

export default MenuConfig;