import React ,{ Component } from 'react';
import { Button, Form , Select , DatePicker ,Cascader } from 'antd';
import baseUrl from '../../../configs/postUrl';
import dataConfig from '../../../configs/fetchConfig';
import moment from 'moment';

const {  RangePicker } = DatePicker;
class WrappedDemo extends Component{
    constructor(props){
        super(props)
        this.state={
            token:localStorage.userToken,
            agentList:[],//学校列表
            teacherList:[],//外教列表
            group:'1',//是否只查询班课老师：1是，0不是
            assistantList:[],//助教列表
            bookId:0,//课件id
            selectedBookId:'',//当前选中的课件
            bookList:[],//课件列表  
            changeState:false,     
            defaultSelectDate:[moment().startOf('day'), moment().endOf('day')]   
        }
        this.getBookList=this.getBookList.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.dealAllList=this.dealAllList.bind(this)
    }
    componentWillMount(){
        this.getAssistantList()
        this.getAgentList()
        this.getTeacherList()
        this.getBookList()
    }
    // 查询表单
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const timeValue=fieldsValue['range-time-picker']
                console.log(timeValue)
                // 如果时间选项没值则不用处理
                if(timeValue===undefined||timeValue===null){
                    this.props.queryData(fieldsValue,this.state.selectedBookId)
                }else{
                    const rangeTimeValue = fieldsValue['range-time-picker'];
                    const values = {
                        ...fieldsValue,
                        'range-time-picker': [
                            rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'),
                            rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss'),
                        ],
                    };
                    this.props.queryData(values,this.state.selectedBookId)
                }   
            }
        });
    }
    // 重置
    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
            defaultSelectDate:null
        },()=>
            this.props.form.validateFields((err, values) => {
                this.props.queryData(values,'')
                console.log(values)
            })
        )
        
    }
    // 获取代理商助教列表
    getAssistantList(){
        let formData = new FormData();
        formData.append('token',this.state.token)

        fetch(baseUrl+'/setting/get-assistant-list',dataConfig(formData))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
            if(data.code===200){
                this.setState({
                    assistantList:data.data.agent
                })
            }
        })
        .catch(e => console.log('错误:', e))   ///请求出错
    }
    // 获取外教列表
    getTeacherList(){
        let formData = new FormData();
        formData.append('token',this.state.token)
        formData.append('group',this.state.group)

        fetch(baseUrl+'/setting/get-teacher-list',dataConfig(formData))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
            if(data.code===200){
                this.setState({
                    teacherList:data.data.agent
                })
            }
        })
        .catch(e => console.log('错误:', e))   ///请求出错
    }
    //获取学校列表
    getAgentList(){
        let formData = new FormData();
        formData.append('schoolType','1,2,3')

        fetch(baseUrl+'/setting/get-agent-list',dataConfig(formData))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
            if(data.code===200){
                this.setState({
                    agentList:data.data.agent
                })
            }
        })
        .catch(e => console.log('错误:', e))   ///请求出错
    }

    //获取课件列表
    getBookList(){
        let formData = new FormData();
        formData.append('token',this.state.token)

        fetch(baseUrl+'/setting/get-all-books-list',dataConfig(formData))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
            if(data.code===200){                  
                this.setState({
                    bookList:this.dealAllList(data.data.list)
                })
            }   
        }) 
    }

    // 处理课件数据
    dealAllList(list){
        let bookList=[]
        list.map(item=>{
            if(item.son&&JSON.stringify(item.son)!='[]'){
                bookList.push({
                    value:item.id,
                    label:item.title,
                    children:this.dealAllList(item.son)
                })
            }else{
                bookList.push({
                    value:item.id,
                    label:item.title
                })
            }
        })
        return bookList;
    }

    // 获取课件最后一级id
    handleChange(value) {
       this.setState({
            selectedBookId:value[value.length-1]
       })
    }

    
    render(){
        const { getFieldDecorator } = this.props.form;
        const dateFormat='YYYY-MM-DD HH:mm:ss';
        
        return(
            <Form layout="inline" onSubmit={this.handleSubmit}>    
                <Form.Item label='外教：'>
                {getFieldDecorator('teacherId',{initialValue:""})(
                    <Select
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: 200 }}
                        placeholder="请输入">
                    {
                        this.state.teacherList.length && this.state.teacherList.map( (item, index) => (
                            <Select.Option key={item.id} value={item.id}>{item.teachername}</Select.Option>)
                        ) 
                    }
                    </Select>)}
                </Form.Item>
                <Form.Item label='助教：'>
                    {getFieldDecorator('assistantId',{initialValue:""})(
                    <Select
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: 200 }}
                        placeholder="请输入">
                    {
                        this.state.assistantList.length && this.state.assistantList.map( (item, index) => (
                            <Select.Option key={item.id} value={item.id}>{item.fullname}</Select.Option>)
                        ) 
                    }
                    </Select>)}
                </Form.Item>
                <Form.Item label='课件：'>
                    {getFieldDecorator('courseId',{initialValue:""})(
                        <Cascader 
                            options={this.state.bookList} 
                            onChange={this.handleChange} 
                            placeholder="Please select" />
                    )}
                </Form.Item>
                <Form.Item label="上课时间">
                    {getFieldDecorator('range-time-picker',{
                        initialValue:this.state.defaultSelectDate
                    })(                        
                        <RangePicker
                            showTime 
                            format={dateFormat} />
                    )}
                </Form.Item>
                <Form.Item label='学校：'>
                    {getFieldDecorator('agentId',{initialValue:""})(
                    <Select
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: 200 }}
                        placeholder="请输入">
                    {
                        this.state.agentList.length && this.state.agentList.map( (item, index) => (
                            <Select.Option key={item.userid} value={item.userid}>{item.company}</Select.Option>)
                        ) 
                    }
                    </Select>)}
                </Form.Item>
                <Form.Item label='课程性质：'>
                    {getFieldDecorator('classType',{initialValue:""})(
                    <Select
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{ width: 200 }}
                        placeholder="请输入">
                            <Select.Option value='0'>正式课</Select.Option>
                            <Select.Option value='1'>体验课</Select.Option>
                    </Select>)}
                </Form.Item>
                <Form.Item>
                <Button type="primary" htmlType="submit">查询</Button>
                </Form.Item>
                <Form.Item>
                <Button onClick={this.handleReset}>重置</Button>
                </Form.Item>
            </Form>
        )          
    }
}

const InlineForm = Form.create({ name: 'customized_form_controls' })(WrappedDemo);

export default InlineForm;