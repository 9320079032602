import React,{Component} from 'react';
import { message , Divider } from 'antd';
import QueryInputs from './queryInputs/queryInputs';
import Table from '../../components/commonTable/commonTable';
import baseUrl from '../../configs/postUrl';
import momentt from '../../configs/timetool.js';
import Modal from './formModal/formModal';
import dataConfig from '../../configs/fetchConfig';
import moment from 'moment'

class superviceClass extends Component{
    constructor(props){
      super(props)
      this.state={
        roomLists:[],
        page:'',
        loading:true,
        token:localStorage.userToken,

        // 获取课程信息列表
        classType:'',//课程性质
        teacherId:'',//教师id
        assistantId:'',//助教id
        courseId:'',
        startTime:moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),//默认为当前时间0-24点
        endTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        agentId:'',//合作校id
        pageSize:6,
        p:1,
      }
      this.getQueryData=this.getQueryData.bind(this)
      this.getData=this.getData.bind(this)
    }

    componentWillMount(){
        this.getClassList()
    }

    // 获取课程信息列表
    getClassList(){
        this.setState({loading:true})
        let formData = new FormData();
        formData.append('token', this.state.token)
        formData.append('classType', this.state.classType)
        formData.append('teacherId', this.state.teacherId)
        formData.append('assistantId', this.state.assistantId)
        formData.append('courseId', this.state.courseId)
        formData.append('startTime', this.state.startTime)
        formData.append('endTime', this.state.endTime)
        formData.append('agentId', this.state.agentId)
        formData.append('pageSize', this.state.pageSize)
        formData.append('p', this.state.p)

        fetch(baseUrl+'/v1/ta-manage/get-class-list',dataConfig(formData))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
          if(data.code===200){
            const classData=this.dealWithColum(data.data.data)
            this.setState({
              classLists:classData,
              loading:false,
              page:data.data.pages,
            });

            if(JSON.stringify(data.data.data) === '[]'){
                message.info('无数据');
            }
          }else if(data.code===-1){
            message.info(data.msg)
          }else if(data.code===-2){
            message.warning(data.msg)
            this.props.history.push('/login')
          }  
        })
        .catch(e => console.log('错误:', e))   ///请求出错
    }
    //表格数据处理
    dealWithColum(classData){
      classData.map((item,key)=>{
        classData[key].schoolName=item.agent.company
        classData[key].className=item.agentClass?item.agentClass.name:''
        classData[key].assistantName=item.agentClass.assistant?item.agentClass.assistant.fullname:''
        classData[key].startTime=momentt(item.classScheduleGlv.starttime)
        classData[key].teacherName=item.classScheduleGlv.teacher.teachername
        classData[key].classTypeJudge=item.classtype==='2'?'体验课':'正式课'
        classData[key].id=key
      })
      return classData;
    }
    
    //课程详情弹框
    showModal = (id) => {
      this.refs.formodal.showModal(id)
    } 

    // 获取查询参数
    getQueryData(values,bookid){
      this.setState({
        teacherId:values.teacherId,
        assistantId:values.assistantId,
        courseId:bookid,//课件id
        agentId:values.agentId,
        classType:values.classType,
        startTime:values['range-time-picker']?values['range-time-picker'][0]:'',
        endTime:values['range-time-picker']?values['range-time-picker'][1]:'',
        p:1
      },()=>{this.getClassList()})
    }

    //表格分页
    getData(page){
      this.setState({
        p:page
      },()=>{
        this.getClassList()
      })
    }
    render(){
        const columns = [
          {
            key:'Grouporderid',
            title: '课程ID',
            dataIndex: 'grouporderid',
          },{
            key:'SchoolName',
            title: '学校',
            dataIndex: 'schoolName',
          }, {
            key:'ClassName',
            title: '班级',
            dataIndex: 'className',
          }, {
            key:'AssistantName',
            title: '助教',
            dataIndex: 'assistantName',
          },{
            key:'StartTime',
            title: '上课时间',
            dataIndex: 'startTime',
          },{
            key:'TeacherName',
            title: '外教',
            dataIndex: 'teacherName',
          },{
            key:'CourseName',
            title: '教材',
            dataIndex: 'courseName'
          },{
            key:'ClassTypeJudge',
            title: '课程性质',
            dataIndex: 'classTypeJudge',
          },{
            key:'Caozuo',
            title: '操作', 
            dataIndex: 'grouporderid',
            width:120,
            render: (text, record) => 
              {
                const fontStyle={color: '#1890ff',cursor:'pointer'};
                let operateBtn;
                if(record.viewStatus===1){
                  operateBtn=(<span style={{color:'grey'}}>等待开课</span>)
                }else if(record.viewStatus===2){

                  if(record.liveUrl){
                    if(record.liveUrl.front&&record.liveUrl.back){
                      operateBtn=( 
                        <span><a href={record.liveUrl.front} target='blank'>观看学生</a>
                        <Divider type="vertical" />
                        <a href={record.liveUrl.back} target='blank'>观看老师</a></span> 
                      )
                    }else if(record.liveUrl.front&&!record.liveUrl.back){
                      operateBtn=( 
                        <span>
                          <a href={record.liveUrl.front} target='blank'>观看学生</a>
                        </span> 
                      )
                    }else if(!record.liveUrl.front&&record.liveUrl.back){
                      operateBtn=( 
                        <span>
                          <a href={record.liveUrl.back} target='blank'>观看老师</a>
                        </span> 
                      )
                    }
                  }
                }
                
              return(
                <div>
                  <span style={fontStyle} 
                    onClick={this.showModal.bind(this,text)}>课程详情</span>
                  <Divider type="vertical" />
                  {operateBtn}
                </div>
              )
          },
        }];
        
        return(
            <div>
                <Modal ref='formodal'></Modal>
                <QueryInputs queryData={this.getQueryData}></QueryInputs>
                <Table 
                  getData={this.getData}
                  loading={this.state.loading}
                  data={this.state.classLists}
                  pages={this.state.page}
                  columns={columns}></Table>         
            </div>
        )
    }
}
export default superviceClass;