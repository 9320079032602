import React , { Component } from 'react';
import { message } from 'antd';
import Table from '../commonTable/commonTable';
import baseUrl from './../../configs/postUrl';
import FormInline from './queryRoom/queryRoom';
import timestampToTime from '../../configs/timetool';
import dataConfig from '../../configs/fetchConfig';

message.config({
  top: 300,
  duration: 2,
  maxCount: 3,
});

class feedBack extends Component{
  constructor(props){
    super(props)
    this.state={
      token:localStorage.userToken,
      loading:true,
      initData:[],
      page:[],
      //反馈信息列表
      roomCode:'',
      pageSize:6,
      p:1
    }
    this.getFeedbackList=this.getFeedbackList.bind(this)
    this.getCode=this.getCode.bind(this)
    this.getData=this.getData.bind(this)
  }
  componentWillMount(){
    this.getFeedbackList()
  }

  // 获取意见反馈列表
  getFeedbackList(){
    this.setState({loading:true})
    let formData = new FormData();
    formData.append('token', this.state.token)
    formData.append('roomCode', this.state.roomCode===undefined?'':this.state.roomCode)
    formData.append('pageSize', this.state.pageSize)
    formData.append('p', this.state.p)

    fetch(baseUrl+'/v1/recruit-feedback/list',dataConfig(formData))
    
    .then(res => res.json())  ///解析json数据
    .then(data => {
      if(data.code===200){
        this.setState({
          initData:data.data.recruitFeedback,
          page:data.data.pages,
          loading:false
        });
        
          //如果列表为空
        if(JSON.stringify(data.data.recruitFeedback) === '[]'){
          message.info('无数据')
        }else{
          // 不为空时才可以格式化数据
          const initdata=this.dealWithColum(data.data.recruitFeedback)
          this.setState({
            initData:initdata,
          });
        }  
      }else if(data.code===-1){
        message.info(data.msg)
      }else if(data.code===-2){
        message.warning(data.msg)
        this.props.history.push('/login')
      }
    })
    .catch(e => console.log('错误:', e))   ///请求出错
  }

  // 处理表格数据
  dealWithColum(initdata){
    initdata.map((item,key)=>{
      initdata[key].authorName=item.nickname
      initdata[key].index=key+1
      initdata[key].createdTime=timestampToTime(item.createdTime)
    })
    return initdata;
  }

  //获取表单房间号
  getCode(code){
    this.setState({
      roomCode:code,
      p:1
    },()=>{this.getFeedbackList()})
  }
  
  //获取表格页码
  getData(page){
    this.setState({
      p:page
    },()=>{
      this.getFeedbackList()
    })
  }

  render() {
    
    const columns = [{
      key:'index',
      title: '编号',
      dataIndex: 'index',
    }, {
      key:'RoomCode',
      title: '房间号',
      dataIndex: 'roomCode',
    }, {
      key:'AuthorName',
      title: '反馈用户',
      dataIndex: 'authorName',
      render: (text, record) =>(
        <span>
          {record.invitationCode?`${record.invitationCode}—`:''}
          {text}
        </span>
      ) 
    },{
      key:'CreatedTime',
      title: '反馈时间',
      dataIndex: 'createdTime',
    },{
      key:'Id',
      title: '操作',
      dataIndex: 'id',
      render: text =>(<a href={`https://livestream.ehailuo.com/#/feedback?id=${text}`} target="_blank">查看详情</a>),
    }];
    return(
      <div>
        <FormInline getCode={this.getCode}/>
        <Table  
          columns={columns} 
          pages={this.state.page}
          getData={this.getData}
          loading={this.state.loading}
          data={this.state.initData} />
      </div>
    )
  }
}

export default feedBack;
