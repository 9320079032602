import React , { Component } from 'react';
import { Input, Form ,Button } from 'antd';

class QueryRoom extends Component{
    
    // 点击查询
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
              this.props.getCode(values.roomNum)
            }
        }); 
    }

    // 重置数据
    handleReset = () => {
      this.props.form.resetFields();
      this.props.getCode('')
    }
    render(){
      const { getFieldDecorator } = this.props.form;   
      return(
        <Form layout="inline" onSubmit={this.handleSubmit}>
          <Form.Item label='房间号：'>
          {getFieldDecorator('roomNum')(
              <Input placeholder="请输入" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">查询</Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={this.handleReset}>重置</Button>
          </Form.Item>
        </Form>
      )
    }
  }
  const FormInline=Form.create({ name: 'customized_form_controls' })(QueryRoom);
  export default FormInline;