import React ,{ Component } from 'react';
import { Button, Input, Form,Select } from 'antd';
import baseUrl from './../../../configs/postUrl';
import dataConfig from '../../../configs/fetchConfig';

class WrappedDemo extends Component{
    constructor(props){
        super(props)
        this.state={
            adminList:[ ]   //创建人列表
        }
    }
    componentWillMount(){
        this.getAdminList()
    }
    
    // 查询表单
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.queryData(values)
            }
        });
    }
    // 重置表单数据
    handleReset = () => {
        this.props.form.resetFields();
        this.props.form.validateFields((err, values) => {
            this.props.queryData(values)
        });
    }
    // 获取创建人列表
    getAdminList(){
    
        fetch(baseUrl+'/setting/get-admin-list',dataConfig(''))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
            if(data.code===200){
                this.setState({
                    adminList:data.data.admins
                })
            }
        })
        .catch(e => console.log('错误:', e))   ///请求出错
    }
    handleChange(value) {
        console.log(`selected ${value}`);
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            <Form layout="inline" onSubmit={this.handleSubmit}>    
                <Form.Item label='创建人：'>
                {getFieldDecorator('username')(
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="请输入"
                        optionFilterProp="children"
                        onChange={this.handleChange}
                        filterOption={(input, option) => 
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                    {
                        this.state.adminList.length && this.state.adminList.map( (item, index) => (
                            <Select.Option key={item.id} value={item.id}>{item.realName}</Select.Option>)
                        ) 
                    }
                    </Select>)}
                </Form.Item>
                <Form.Item label='房间号：'>
                {getFieldDecorator('roomnum')(
                    <Input placeholder="请输入" />)}
                </Form.Item>
                <Form.Item label='外教名称：'>
                {getFieldDecorator('teacher')(
                    <Input placeholder="请输入" />)}
                </Form.Item>
                <Form.Item>
                <Button type="primary" htmlType="submit">查询</Button>
                </Form.Item>
                <Form.Item>
                <Button onClick={this.handleReset}>重置</Button>
                </Form.Item>
            </Form>
        )          
    }
}

const InlineForm = Form.create({ name: 'customized_form_controls' })(WrappedDemo);

export default InlineForm;