import React,{Component} from 'react'
import { Link,withRouter } from 'react-router-dom';
import MenuConfig from '../../configs/MenuConfig';
import { Menu, Icon } from 'antd';
const SubMenu = Menu.SubMenu;


class sideBar extends Component{

    state = {
        defaultSelectedKey:MenuConfig[0].children[0].key,
        defaultOpenKey:MenuConfig[0].key,
    };

    componentWillMount(){
        const menuTreeNode = this.renderMenu(MenuConfig);  
        this.setState({
            menuTreeNode:menuTreeNode,
        })
        const { location } = this.props;     
        const url = location.pathname  
        const urlPre=url.substring(url.indexOf('/'),url.lastIndexOf('/'))
        if(urlPre){
            this.setState({
                defaultSelectedKey:url,
                defaultOpenKey:urlPre,
            })
        }
    }
    componentDidUpdate(){
        
    }
    // 左边导航栏渲染（通过递归的方式进行渲染）
    renderMenu = (data)=>{
        return data.map((item)=>{
            if(item.children){//当有子集存在的时候，需要再次调用遍历
                return (
                    <SubMenu 
                        title={<span>{item.icon && <Icon type={item.icon} />}{item.title} </span>}
                        key={item.key}>
                        {this.renderMenu(item.children)}
                    </SubMenu>
                ) 
            }
            return (
                <Menu.Item title={item.title} key={item.key}>
                    <Link to={item.url}>
                        {item.title}
                    </Link> 
                </Menu.Item>
            )
        })
    }
    onOpenChange = (openKeys) => {
        const latestOpenKey = openKeys.find(key => this.state.defaultOpenKey.indexOf(key) === -1);
        const rootSubmenuKeys=[]
        MenuConfig.map(item=>{
            rootSubmenuKeys.push(item.key)
        })
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.setState({ openKeys });
        } else {
          this.setState({
            openKeys: latestOpenKey ? [latestOpenKey] : [],
          });
        }
    }
    render(){
        return(
            <div style={{height:'100%'}}>
                <Menu
                    theme='dark'
                    mode="inline"
                    onOpenChange={this.onOpenChange}
                    style={{ height: '100%', borderRight: 0 }}
                    defaultSelectedKeys={[this.state.defaultSelectedKey]}
                    defaultOpenKeys={[this.state.defaultOpenKey]}
                    >
                    {this.state.menuTreeNode}
                </Menu>
            </div>
        )
    }
}
export default withRouter(sideBar);