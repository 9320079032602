import React,{ Component } from 'react';
import { Form, Input ,DatePicker,Select } from 'antd';
import baseUrl from '../../../configs/postUrl';
import dataConfig from '../../../configs/fetchConfig';

const {  RangePicker } = DatePicker;

class fromModal extends Component{
    constructor(props){
      super(props)
      this.state={
          countryList:[]//国家列表
      }
    }
    componentWillMount(){
      this.getcountryList()
    }

    //获取国家列表
    getcountryList(){

      fetch(baseUrl+'/setting/get-country',dataConfig(''))
      
      .then(res => res.json())  ///解析json数据
      .then(data => {
        if(data.code===200){
          this.setState({
              countryList:data.data.countries
          })
        }
      })
      .catch(e => console.log('错误:', e))   ///请求出错
    }

    //表单
    render(){
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 8 },
      };
      const rangeConfig = {
        rules: [{ type: 'array', required: true, message: 'Please select time!' }],
      };
      // 时间格式
      const dateFormat='YYYY-MM-DD HH:mm:ss';
      return(
        <Form onSubmit={this.handleSubmit}>
            <Form.Item
              label="房间名称"
             {...formItemLayout}
            >
              {getFieldDecorator('roomName', {
                rules: [{ required: true, message: 'Please input your note!' }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              label="TA skype"
             {...formItemLayout}
            >
              {getFieldDecorator('skype', {
                rules: [{ message: 'Please input your note!' }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="直播时间"
            >
              {getFieldDecorator('range-time-picker', rangeConfig)(
                <RangePicker
                  showTime 
                  format={dateFormat} />
              )}
            </Form.Item>
            <Form.Item
              label="外教名称"
             {...formItemLayout}
            >
              {getFieldDecorator('teacherName', {
                rules: [{ message: 'Please input your note!' }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              label="外教国家"
             {...formItemLayout}
            >
              {getFieldDecorator('teacherCountry', {
                rules: [{ message: 'Please input your note!' }],
              })(
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="请输入"
                    optionFilterProp="children"
                    filterOption={(input, option) => 
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                {
                    this.state.countryList.length && this.state.countryList.map( (item, index) => (
                        <Select.Option key={item.id} value={item.id}>{item.country}</Select.Option>)
                    ) 
                }
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="外教邮箱"
             {...formItemLayout}
            >
              {getFieldDecorator('teacherEmail', {
                rules: [{ message: 'Please input your note!' }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              wrapperCol={{ span: 12, offset: 5 }}
            >
            </Form.Item>
          </Form>
      )
    }

}
const ModalForm=Form.create({ name: 'coordinated' })(fromModal);

export default ModalForm;