import React ,{ Component } from 'react';
import {  Modal , message ,Icon} from 'antd';
import baseUrl from '../../../configs/postUrl';
import dataConfig from '../../../configs/fetchConfig';

message.config({
    top: 300,
    duration: 2,
    maxCount: 3,
});

class modal extends Component{
  
    state = { 
        visible: false ,
        token:localStorage.userToken ,
        classDetail:{ } ,//课程详情
        studentCourseware:'',//学员课件
        teacherCourseware:'',//老师课件
        twoTeachers:'',//双师教案
        audioCourseware:'',// 音频课件
    }
    
    // 打开弹窗
    showModal = (param) => {
        this.setState({
            visible: true,
        });
        // 获取父组件id展示数据
        this.getClassDetail(param)
    }

    //获取课程详情信息
    getClassDetail(gid){
        let formData = new FormData();
        formData.append('token', this.state.token)
        formData.append('grouporderid', gid)

        fetch(baseUrl+'/v1/ta-manage/get-class-detail',dataConfig(formData))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
          if(data.code===200){
              
            this.setState({
                classDetail:data.data,
                studentCourseware:
                    data.data.bookGlvData.stu_book_b?data.data.bookGlvData.stu_book_b:'',//学员课件
                teacherCourseware:
                    data.data.bookGlvData.teacher_book_b?data.data.bookGlvData.teacher_book_b:'',//老师课件
                twoTeachers:
                    data.data.bookGlvData.coteacher_book?data.data.bookGlvData.coteacher_book:'',//双师教案
                audioCourseware:
                    data.data.bookGlvData.stu_book_audio?data.data.bookGlvData.stu_book_audio:'',// 音频课件
            })
          }else if(data.code===-1){
            message.info(data.msg)
          }
        })
        .catch(e => console.log('错误:', e))   ///请求出错
    }

    // 点击取消
    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    }

    // 点击确定
    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    }

    render() {
        
        return(
            <div>
                <Modal
                    title="课程详情"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    >
                    <p>
                        <label>课程编号：</label>
                        <span>{this.state.classDetail.classid}</span>
                    </p>
                    <p>
                        <label>课程类型：</label>
                        <span>{this.state.classDetail.classtype===2?'First Class':'Regular Class'}</span>
                    </p>
                    <p>
                        <label>课程规模：</label>
                        <span>班课</span>
                    </p>
                    <p>
                        <label>上课班级：</label>
                        <span>{this.state.classDetail.agentClass?this.state.classDetail.agentClass.name:''}</span>
                    </p>
                    <p>
                        <label>上课教室：</label>
                        <span>{this.state.classDetail.classroomName}</span>
                    </p>
                    <p>
                        <label>所属合作校：</label>
                        <span>{this.state.classDetail.agent?this.state.classDetail.agent.company:""}</span>
                    </p>
                    <p>
                        <label>合作校联系方式：</label>
                        <span>{this.state.classDetail.agent?this.state.classDetail.agent.mobile:''}</span>
                    </p>
                    <p>
                        <label>云海螺负责人：</label>
                        <span>{this.state.classDetail.admin?this.state.classDetail.admin.realName:''}</span>
                    </p>
                    <p>
                        <label>负责人联系方式：</label>
                        <span>{this.state.classDetail.admin?this.state.classDetail.admin.mobile:''}</span>
                    </p>
                    <p>
                        <label>上课时间：</label>
                        <span>
                        {this.state.classDetail.classScheduleGlv?this.state.classDetail.classScheduleGlv.starttime:''}
                        -{this.state.classDetail.classScheduleGlv?this.state.classDetail.classScheduleGlv.endtime:''}</span>
                    </p>
                    <p>
                        <label>老师姓名：</label>
                        <span>{this.state.classDetail.teacher?this.state.classDetail.teacher.nickname:''}</span>
                    </p>
                    <p>
                        <label>学生上课工具：</label>
                        <span>{this.state.classDetail.classtoolName} : </span>
                        <span>{this.state.classDetail.classtoolaccount}</span>
                    </p>
                    <p>
                        <label>老师上课工具：</label>
                        <span>{this.state.classDetail.classtoolName} : </span>
                        <span>{this.state.classDetail.classtoolTeacher}</span>
                    </p>
                    <p>
                        <label>课件名称：</label>
                        <span>{ this.state.classDetail.courseName}</span>                      
                    </p>
                    <p>
                        <label>学员课件下载：</label>
                        {
                            this.state.studentCourseware&&this.state.studentCourseware.map((item,key)=>(
                                <a key={key} href={item} target='blank'><Icon type="file" /></a>
                            ))
                        }
                    </p>
                    <p>
                        <label>老师课件下载：</label>
                        {
                            this.state.teacherCourseware&&this.state.teacherCourseware.map((item,key)=>(
                                <a key={key} href={item} target='blank'><Icon type="file" /></a>
                            ))
                        }
                    </p>
                    <p>
                        <label>双师中教教案：</label>
                        {
                            this.state.twoTeachers&&this.state.twoTeachers.map((item,key)=>(
                                <a key={key} href={item} target='blank'><Icon type="file" /></a>
                            ))
                        }
                    </p>
                    <p>
                        <label>音频课件下载：</label>
                        {
                            this.state.audioCourseware&&this.state.audioCourseware.map((item,key)=>(
                                <a key={key} href={item} target='blank'><Icon type="file" /></a>
                            ))
                        }
                    </p>
                    <p>
                        <label>老师对这节课的评价：</label>
                        <span>{this.state.classDetail.teacherToCourseEval}</span>
                    </p>
                    <p>
                        <label>老师对这节课学员的评价：</label>
                        <span>{this.state.classDetail.agentToStudent}</span>
                    </p>
                </Modal>
            </div>
        ) 
    }
}

export default modal;