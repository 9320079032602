import React, { Component } from 'react';
import { BrowserRouter as Router,Route ,Redirect} from 'react-router-dom';
import './App.css';
import Login from './pages/login/Login';
import homePage from './pages/home/homePage'
import PrivateRoute from "../src/configs/router/privateRoute";

class App extends Component {

  render() {
    return (
          // <Router>
          //   <div className="App">
          //     <Route path='/' exact render={()=>(
          //       //exact是用于精准匹配，这里是定义了路由重定向，意思是当我们进入"/"时会重定向到“/index”
          //       <Redirect to='/home'/>
          //     )}/>
          //     <Route path='/home' component={homePage}></Route>
          //     <Route path='/login' component={Login}></Route>
          //   </div>
          // </Router>
          <Router>
            <div className="App">
              <Route path='/' exact render={()=>(
                //exact是用于精准匹配，这里是定义了路由重定向，意思是当我们进入"/"时会重定向到“/index”
                <Redirect to='/home'/>
              )}/>
              <PrivateRoute path='/home' component={homePage}></PrivateRoute>
              <PrivateRoute path='/login' component={Login}></PrivateRoute>
            </div>
          </Router>
    );
  }
}

export default App;
