import React,{ Component } from 'react';
import { Table  } from 'antd';

class TableMade extends Component{
    constructor(props){
        super(props)
        this.state={
            pageSize:2,
            sourceData:[],
            current:1
        }
    }

    componentWillReceiveProps(nextProps) {
        let newdata = nextProps.data
        this.setState({
            sourceData:newdata,
        })
    }

    // 表格事件
    handleChange = (pagination) => {
        // console.log('Various parameters', pagination, filters, sorter);
        this.getData(pagination.current)
    }
    
    getData=(p)=>{
        this.props.getData(p);
    }    
    render() { 
        return (
            <div>
                <Table  
                    columns={this.props.columns} 
                    pagination={{
                        total: Number(this.props.pages.totalCount),
                        pageSize: Number(this.props.pages.pageSize), 
                        current:Number(this.props.pages.p),
                        showQuickJumper:true
                    }}
                    loading={this.props.loading}
                    rowKey={record=>record.id}
                    dataSource={this.state.sourceData} 
                    onChange={this.handleChange} />
            </div>
        );
    }
}

export default TableMade;