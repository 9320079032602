import React,{ Component } from "react";
import {
    Form, Icon, Input, Button, Checkbox,message
} from 'antd';
import baseUrl from './../../configs/postUrl';
import './login.css';
import ehelloPng from '../../styles/images/ehello.png';
import dataConfig from '../../configs/fetchConfig';
import md5 from 'md5';

message.config({
    top: 300,
    duration: 2,
    maxCount: 3,
});
class NormalLoginForm extends Component{
    constructor(props){
        super(props)
        this.state={
            username:'',
            password:''
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err) {
                // 处理请求
                this.setState({
                    username:values.userName,
                    password:md5(values.password)
                },()=>{
                    this.getToken()
                })
            }
        });
    }
    //请求token
    getToken(){
        let formData = new FormData();
        formData.append('username',this.state.username)
        formData.append('password',this.state.password)
       
        fetch(baseUrl+'/v1/user/login',dataConfig(formData))
        
        .then(res => res.json())  ///解析json数据
        .then(data => {
            if(data.code===200){
                localStorage.userToken=data.data.token
                localStorage.userName=data.data.username
                localStorage.sendId=data.data.id
                localStorage.realName=data.data.realName
                this.props.history.push('/home')
            }else{
                message.warning(data.msg)
            }
        }).catch(e => console.log('错误:', e))   ///请求出错
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='loginBg'>
                <div className='ehelloLogo'>
                    <img src={ehelloPng} alt=''/>
                </div>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item>
                    {getFieldDecorator('userName', {
                        rules: [{ required: true, message: 'Please input your username!' }],
                    })(
                        <Input 
                            prefix={<Icon type="user" 
                            style={{ color: 'rgba(0,0,0,.2)' }} />} 
                            placeholder="账户" />
                    )}
                    </Form.Item>
                    <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                        <Input 
                            prefix={<Icon type="lock" 
                            style={{ color: 'rgba(0,0,0,.2)' }} />} 
                            type="password" 
                            placeholder="密码" />
                    )}
                    </Form.Item>
                    <Form.Item>
                    {/* {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: false,
                    })(
                        <Checkbox>自动登录</Checkbox>
                    )} */}
        
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        登录
                    </Button>

                    </Form.Item>
                </Form>
            </div>
        );
    }
    
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default WrappedNormalLoginForm;